<template>
    <div class="reload-outfit-wrapper">
        <div class="reload">
            <a href="" @click.prevent="reloadOutfit()">
                <center>
                    <img :src="require('@/assets/images/general/reload-sticker.png')">
                </center>
                <p>Tap/click to Reload</p>
            </a>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    outfitItem: {
      required: true
    }
  },
  methods: {
    reloadOutfit () {
      this.$emit('reloadOutfit', this.outfitItem)
    }
  }
}
</script>
