<template>
  <div class="first-feed-view">
    <div class="small-container">
      <div class="first-feed-view-img">
        <img :src="require('@/assets/images/general/' + fvFeed.image)" width="100%" alt=""/>
      </div>
      <h4 class="h4-style">
      {{  isDemoOutfit ? demoFeed.content : fvFeed.content }}
      <br>
      <strong>{{ isDemoOutfit ? demoFeed.description : '' }}</strong>
      </h4>
      <div class="yellow-button">
        <router-link :to="{ name: 'SignUpEmail' }" v-if="isDemoOutfit" type="button" class="btn-default btn-shadow">{{ demoFeed.button }}</router-link>
        <button v-else type="button" class="btn-default" @click="$emit('addOutfit', true)" v-html="fvFeed.button" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isDemoOutfit: {
      default: false
    }
  },
  data () {
    return {
      fvFeed: {
        image: 'oops.svg',
        content: this.__t('feed.empty-space'),
        button: this.__t('feed.add-outfit-now')
      },
      demoFeed: {
        content: this.__t('feed.empty-space'),
        description: this.__t('feed.empty-space-description'),
        button: this.__t('feed.sign-up')
      }
    }
  }
}
</script>
